export function makeImpactText(value: string): string {
  let impactText = "";
  if (value === "S") {
    impactText = "Small";
  } else if (value === "M") {
    impactText = "Medium";
  } else if (value === "L") {
    impactText = "Large";
  }
  return impactText;
}
