import React from "react";
import Box from "@mui/joy/Box";
import { Chip, Radio, RadioGroup } from "@mui/joy";
import { ActivitySuggestion } from "../../models/Activity";

interface Props {
  tags: ActivitySuggestion[];
  selected: ActivitySuggestion | null;
  onSelect: (tag: ActivitySuggestion) => void;
}

function TagSelection(props: Props) {
  const { tags, selected, onSelect } = props;
  return (
    <Box role="group" sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      <div>
        <RadioGroup
          name="best-movie"
          aria-labelledby="best-movie"
          orientation="horizontal"
          sx={{ flexWrap: "wrap", gap: 1 }}
        >
          {tags.map((tag) => {
            const checked = selected === tag;
            return (
              <Chip
                key={tag.description}
                variant="plain"
                color={checked ? "primary" : "neutral"}
              >
                <Radio
                  variant="outlined"
                  color={checked ? "primary" : "neutral"}
                  disableIcon
                  overlay
                  label={tag.description}
                  value={tag}
                  checked={checked}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onSelect(tag);
                    }
                  }}
                />
              </Chip>
            );
          })}
        </RadioGroup>
      </div>
    </Box>
  );
}

export default TagSelection;
