import React from "react";
import {
  Avatar,
  Button,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Sheet,
  Typography,
} from "@mui/joy";
import packageJson from "../../package.json";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useFetcher, useRouteLoaderData } from "react-router-dom";
import { User } from "@auth0/auth0-spa-js";

function Profile() {
  let fetcher = useFetcher();
  // Get our logged in user, if they exist, from the root route loader data
  let { user } = useRouteLoaderData("root") as {
    user: User | null | undefined;
  };
  return (
    <Sheet
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        height: "500px",
        maxWidth: "800px",
        mx: "auto",
        my: 2,
      }}
    >
      <div style={{ flexGrow: 1 }}></div>
      <Avatar
        sx={{ marginBottom: "22px" }}
        size="lg"
        src={user?.picture}
        alt={user?.name}
      >
        {user?.picture}
      </Avatar>
      <Typography level="h4">{user?.name}</Typography>
      <div style={{ flexGrow: 1 }}></div>
      <List>
        <ListItem>
          <ListItemButton>
            <ListItemContent>
              <Link href="https://eas-web.pages.dev/about">About</Link>
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemContent>
              <Link href="https://eas-web.pages.dev/privacy-policy">
                Privacy Policy
              </Link>
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemContent>
              {" "}
              <Link href="https://eas-web.pages.dev/terms-and-conditions">
                Terms and Conditions
              </Link>
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </ListItem>
      </List>
      <div style={{ flexGrow: 1 }}></div>
      <fetcher.Form method="post" action="/logout">
        <Button type="submit" variant="plain">
          Log Out
        </Button>
      </fetcher.Form>
      <div style={{ flexGrow: 1 }}></div>
      <Typography level="body-sm">Version {packageJson.version}</Typography>
    </Sheet>
  );
}

export default Profile;
