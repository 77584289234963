import React, { useState } from "react";
import ActivityDescription from "./ActivityDescribe";
import ActivityImpact from "./ActivityImpact";
import { ActivityContextProvider } from "./ActivityContext";
import { useNavigate } from "react-router-dom";

function ActivityFlow(): JSX.Element {
  const [currentStep, setCurrentStep] = useState(1);

  const navigate = useNavigate();

  const goToNextStep = () => {
    if (currentStep === 2) {
      navigate("/activity");
    }
    setCurrentStep(currentStep + 1);
  };

  let currentScreen;

  switch (currentStep) {
    case 1:
      currentScreen = <ActivityDescription onNext={goToNextStep} />;
      break;
    case 2:
      currentScreen = <ActivityImpact onNext={goToNextStep} />;
      break;
    default:
      currentScreen = <div>Invalid step</div>;
  }

  return <ActivityContextProvider>{currentScreen}</ActivityContextProvider>;
}

export default ActivityFlow;
