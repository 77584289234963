import React from "react";
const RoundButton = ({
  text,
  highlightColor,
  size = "small",
  onClick,
}: {
  text: string;
  highlightColor: string;
  onClick: () => void;
  size?: "small" | "medium" | "large";
}) => {
  let dimension = "60px";
  if (size === "medium") {
    dimension = "80px";
  } else if (size === "large") {
    dimension = "100px";
  }
  const buttonStyle = {
    backgroundColor: highlightColor,
    border: "none",
    borderRadius: "50%",
    // width: "100px" /* Adjust the width and height as needed */,
    // height: "100px",
    color: "white" /* Text color */,
    fontSize: "16px" /* Adjust font size as needed */,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none" /* Remove outline on focus */,
    width: dimension,
    height: dimension,
  };

  return (
    <button style={buttonStyle} className="round-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default RoundButton;
