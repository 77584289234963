import { ReactNode, createContext, useContext, useState } from "react";

export type ActivitySize = "S" | "M" | "L";

export type ActivityState = {
  description: string;
  transaction: "Deposit" | "Withdrawal";
  size: ActivitySize;
  note?: string | null;
};

const ActivityContext = createContext<{
  activityState: ActivityState;
  setActivityState: React.Dispatch<React.SetStateAction<ActivityState>>;
}>({
  activityState: {
    description: "",
    transaction: "Deposit",
    size: "S",
    note: null,
  },
  setActivityState: () => {},
});

export const useActivityContext = () => {
  return useContext(ActivityContext);
};

interface ActivityContextProviderProps {
  children: ReactNode;
}

export const ActivityContextProvider: React.FC<
  ActivityContextProviderProps
> = ({ children }) => {
  const [sharedState, setSharedState] = useState<ActivityState>({
    description: "",
    transaction: "Deposit",
    size: "S",
  });

  return (
    <ActivityContext.Provider
      value={{ activityState: sharedState, setActivityState: setSharedState }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
