import { Box, Typography } from "@mui/joy";
import React from "react";
import VerticalBar from "./VerticalBar";
import { fetchData } from "../../utils/apiService";
import { Await, defer, useLoaderData } from "react-router-dom";

interface Energy {
  score: number;
}

export async function loader() {
  const apiEndpoint = "/v1/energy";

  const energyPromise = fetchData<Energy>(apiEndpoint, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  return defer({ energyPromise });
}

function EnergyMeter() {
  const data = useLoaderData() as { energyPromise: Promise<Energy> };

  return (
    <Box sx={{ height: "100%" }}>
      <Typography level="h3" component="h1">
        Energy
      </Typography>
      <React.Suspense fallback={<p>Loading energy score...</p>}>
        <Await resolve={data.energyPromise} errorElement={<p>Error element</p>}>
          {(energy) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography sx={{ paddingY: "24px" }}>
                Energy: {energy.score}
              </Typography>
              <VerticalBar score={energy.score} />
            </div>
          )}
        </Await>
      </React.Suspense>
    </Box>
  );
}

export default EnergyMeter;
