import "./VerticalBar.css"; // Import your CSS file for styling

const VerticalBar = ({ score }: { score: number }) => {
  // Calculate the height for the green (positive) and red (negative) portions
  const positiveHeight = score >= 0 ? (score / 100) * 50 : 0;
  const negativeHeight = score < 0 ? (Math.abs(score) / 100) * 50 : 0;

  return (
    <div className="vertical-bar">
      <div className="bar-container">
        {/* Positive portion */}
        <div
          className="bar positive"
          style={{ height: `${positiveHeight}%` }}
        ></div>

        {/* Negative portion */}
        <div
          className="bar negative"
          style={{ height: `${negativeHeight}%` }}
        ></div>
      </div>
    </div>
  );
};

export default VerticalBar;
