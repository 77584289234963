import React, { FC } from "react";

interface CircleProps {
  size: number;
  color: string;
}

const Circle: FC<CircleProps> = ({ size, color }) => {
  const circleStyle: React.CSSProperties = {
    width: size + "px",
    height: size + "px",
    borderRadius: "50%",
    backgroundColor: color,
  };

  return <div className="circle" style={circleStyle}></div>;
};

export default Circle;
