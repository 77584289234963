import {
  Box,
  Button,
  Sheet,
  Stack,
  Typography,
  Textarea,
  ToggleButtonGroup,
} from "@mui/joy";
import React, { useState } from "react";
import { fetchData } from "../../../utils/apiService";
import { useActivityContext } from "./ActivityContext";
import RoundButton from "../../RoundButton";
import { ActivityParams, Note } from "../../../models/Activity";

interface Props {
  onNext: () => void;
}

function ActivityImpact(props: Props) {
  const { onNext } = props;
  const [loading, setLoading] = useState(false);

  const { activityState, setActivityState } = useActivityContext();

  const onSubmit = async () => {
    setLoading(true);
    // Make network call
    const apiEndpoint = "/v1/activity";

    // Build notes
    let notes: Note[] = [];
    if (activityState.note && activityState.note.length > 0) {
      notes = [{ description: activityState.note }];
    }

    // Build payload
    const body: ActivityParams = {
      description: activityState.description,
      activityType: activityState.transaction,
      score: {
        value: activityState.size,
        type: "Size",
      },
      notes: notes,
    };

    await fetchData(apiEndpoint, {
      body: JSON.stringify(body),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    setLoading(false);

    onNext();
  };

  const highlightColor =
    activityState.transaction === "Deposit" ? "green" : "red";

  return (
    <Box>
      <Sheet
        sx={{
          maxWidth: "400px",
          mx: "auto",
          my: 4, // margin top & bottom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <Typography level="h4">
          How did this impact your energy level?
        </Typography>
        <ToggleButtonGroup
          buttonFlex={1}
          aria-label="flex button group"
          sx={{
            p: 2,
            width: "300px",
            maxWidth: "100%",
            resize: "horizontal",
            margin: "auto",
          }}
          value={activityState.transaction}
          onChange={(event, newValue) => {
            // setValue(newValue);
            setActivityState({
              ...activityState,
              transaction: newValue ?? "Deposit",
            });
          }}
        >
          <Button value="Withdrawal">Down</Button>
          <Button value="Deposit">Up</Button>
        </ToggleButtonGroup>
        <Stack direction="row" spacing="12px" alignItems="center" mx="auto">
          <RoundButton
            text="Small"
            size="small"
            highlightColor={highlightColor}
            onClick={() => setActivityState({ ...activityState, size: "S" })}
          />
          <RoundButton
            text="Medium"
            size="medium"
            highlightColor={highlightColor}
            onClick={() => setActivityState({ ...activityState, size: "M" })}
          />
          <RoundButton
            text="Large"
            size="large"
            highlightColor={highlightColor}
            onClick={() => setActivityState({ ...activityState, size: "L" })}
          />
        </Stack>
        <Typography level="body-lg" paddingTop={2}>
          Additional notes
        </Typography>
        <Textarea
          placeholder="Your notes..."
          minRows={3}
          onChange={(event) =>
            setActivityState({
              ...activityState,
              note: event.target.value,
            })
          }
        />
        <Button loading={loading} onClick={() => onSubmit()}>
          Next
        </Button>
      </Sheet>
    </Box>
  );
}

export default ActivityImpact;
