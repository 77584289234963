import * as React from "react";
import Box from "@mui/joy/Box";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import {
  EmojiEmotionsOutlined,
  ListAltOutlined,
  RadioButtonCheckedOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function BottomTabBar() {
  const [index, setIndex] = React.useState(0);
  const colors = ["primary", "danger", "success", "warning"] as const;
  return (
    <Box
      sx={{
        flexGrow: 1,
        // m: -3,
        // p: 3,
        paddingTop: 1,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        position: "fixed",
        bottom: "0px",
        width: "100vw",
      }}
    >
      <Tabs
        size="lg"
        aria-label="Bottom Navigation"
        value={index}
        onChange={(event, value) => setIndex(value as number)}
        sx={(theme) => ({
          p: 1,
          borderRadius: "0px",
          maxWidth: 400,
          mx: "auto",
          boxShadow: theme.shadow.sm,
          "--joy-shadowChannel": theme.vars.palette[colors[index]].darkChannel,
          [`& .${tabClasses.root}`]: {
            whiteSpace: "nowrap",
            transition: "0.3s",
            fontWeight: "md",
            flex: 1,
            [`&:not(.${tabClasses.selected}):not(:hover)`]: {
              opacity: 0.72,
            },
          },
        })}
      >
        <TabList
          variant="plain"
          tabFlex={1}
          size="sm"
          disableUnderline
          sx={{ borderRadius: "sm", p: 0 }}
        >
          <Tab
            disableIndicator
            orientation="vertical"
            {...(index === 0 && { color: colors[0] })}
            component={Link}
            to="/energy"
          >
            <ListItemDecorator>
              <EmojiEmotionsOutlined />
            </ListItemDecorator>
            Energy
          </Tab>
          <Tab
            disableIndicator
            orientation="vertical"
            {...(index === 1 && { color: colors[1] })}
            component={Link}
            to="/activity"
          >
            <ListItemDecorator>
              <ListAltOutlined />
            </ListItemDecorator>
            Activities
          </Tab>
          <Tab
            disableIndicator
            orientation="vertical"
            {...(index === 3 && { color: colors[3] })}
            component={Link}
            to="/insights"
          >
            <ListItemDecorator>
              <TimelineOutlined />
            </ListItemDecorator>
            Insights
          </Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}
