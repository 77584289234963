import React from "react";
import { Activity } from "../../models/Activity";
import Circle from "../Circle";

export default function ActivityListItemDecorator(activity: Activity) {
  return <ActivityTypeSizeDot {...activity}></ActivityTypeSizeDot>;
}

export const ActivityTypeSizeDot: React.FC<Activity> = (activity) => {
  let size = 6;
  if (activity.score.value === "M") {
    size = 8;
  } else if (activity.score.value === "L") {
    size = 10;
  }
  let color = "#e35849";
  if (activity.activityType === "Deposit") {
    color = "#61cf61";
  }
  return <Circle size={size} color={color} />;
};
