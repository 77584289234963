import React, { useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import { Button, FormControl, IconButton, Sheet, Typography } from "@mui/joy";
import TagSelection from "../TagSelection";
import { CloseOutlined } from "@mui/icons-material";
import { useActivityContext } from "./ActivityContext";
import { fetchData } from "../../../utils/apiService";
import { ActivitySuggestion } from "../../../models/Activity";

interface Props {
  onNext: () => void;
}

function ActivityDescription(props: Props) {
  const { onNext } = props;

  const [selected, setSelected] = useState<ActivitySuggestion | null>(null);
  const { activityState, setActivityState } = useActivityContext();
  const [tags, setTags] = useState<ActivitySuggestion[]>([]);

  const handleTextChange = (text: string) => {
    // Update tag selection
    const predefinedSuggestion =
      tags.find((suggestion) => suggestion.description === text) ?? null;
    setSelected(predefinedSuggestion);
    setActivityState({ ...activityState, description: text });
  };

  const handleSelectionChange = (tag: ActivitySuggestion) => {
    setSelected(tag);
    setActivityState({ ...activityState, description: tag.description });
  };

  useEffect(() => {
    console.log("Calling useEffect");
    const apiEndpoint = "/v1/activity/frequent-descriptions";
    const url = `${apiEndpoint}`;

    fetchData<ActivitySuggestion[]>(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setTags(response);
      })
      .catch((error) => {
        console.error(error);
        setTags([]);
      });
  }, []);

  return (
    <Sheet
      sx={{
        maxWidth: "400px",
        mx: "auto",
        my: 4,
        py: 3,
        px: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
    >
      <Typography level="h4">What happened?</Typography>
      <FormControl>
        <Input
          name="Description"
          placeholder="Enter a short description..."
          onChange={(event) => handleTextChange(event.target.value)}
          value={activityState.description}
          endDecorator={
            <IconButton onClick={() => handleTextChange("")}>
              <CloseOutlined />
            </IconButton>
          }
        ></Input>
      </FormControl>
      <TagSelection
        tags={tags}
        selected={selected}
        onSelect={handleSelectionChange}
      />
      <Button onClick={onNext} disabled={activityState.description === ""}>
        Next
      </Button>
    </Sheet>
  );
}

export default ActivityDescription;
