import { Avatar, Box, Stack } from "@mui/joy";
import React from "react";
import { Link } from "react-router-dom";
import ColorSchemeToggle from "./ColorSchemeToggle";

function NavBar() {
  return (
    <Box
      sx={{
        py: "16px",
        px: "16px",
        width: "100%",
        position: "fixed",
        top: 0,
        display: "flex",
        direction: "row",
        background: `var(--joy-palette-background-surface)`,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: `var(--joy-palette-divider)`,
        zIndex: 100,
      }}
    >
      <div style={{ flexGrow: 1 }}></div>
      <Stack direction="row" spacing={2}>
        <ColorSchemeToggle sx={{ ml: "auto" }} />
        <Link to="/profile">
          <Avatar />
        </Link>
      </Stack>
    </Box>
  );
}

export default NavBar;
