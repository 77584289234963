import React from "react";
import "./App.css";
import { CssVarsProvider } from "@mui/joy/styles";
import { Box, CssBaseline } from "@mui/joy";
import {
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import BottomTabBar from "./components/BottomTabBar";
import NavBar from "./components/NavBar";
import ErrorPage from "./components/ErrorPage";
import EnergyMeter, {
  loader as energyLoader,
} from "./components/Energy/EnergyMeter";
import ActivityFlow from "./components/Activity/Create Flow/Navigation";
import Insight from "./components/Insight";
import Profile from "./components/Profile";
import Login, { loginAction, loginLoader } from "./components/Login";
import ActivityList, {
  loader as activityListLoader,
} from "./components/Activity/ActivityList";
import ActivityItem, {
  loader as activityLoader,
} from "./components/Activity/ActivityItem";
import { auth0AuthProvider } from "./utils/auth";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    errorElement: <ErrorPage />,
    async loader(args) {
      const protectedRedirect = await protectedLoader(args);
      if (protectedRedirect) return protectedRedirect;
      // Route provides the user, if logged in
      let user = await auth0AuthProvider.user();
      return { user };
    },
    Component: Layout,
    children: [
      {
        index: true, // TODO: Review where this should be
        path: "energy",
        element: <EnergyMeter />,
        async loader(args) {
          const protectedRedirect = await protectedLoader(args);
          if (protectedRedirect) return protectedRedirect;
          return energyLoader();
        },
      },
      {
        path: "activity",
        element: <ActivityList />,
        async loader(args) {
          const protectedRedirect = await protectedLoader(args);
          if (protectedRedirect) return protectedRedirect;
          const { params } = args;
          return activityListLoader(params.from, params.to);
        },
      },
      {
        path: "activity/:activityId",
        element: <ActivityItem />,
        async loader(args) {
          const { params } = args;
          const protectedRedirect = await protectedLoader(args);
          if (protectedRedirect) return protectedRedirect;
          return activityLoader(params.activityId ?? "0");
        },
      },
      {
        path: "activity/create",
        element: <ActivityFlow />,
        loader: protectedLoader,
      },
      {
        path: "insights",
        element: <Insight />,
        loader: protectedLoader,
      },
      {
        path: "/profile",
        element: <Profile />,
        loader: protectedLoader,
      },
    ],
  },
  {
    path: "login",
    action: loginAction,
    loader: loginLoader,
    Component: Login,
  },
  {
    path: "/login-result",
    async loader() {
      await auth0AuthProvider.handleSigninRedirect();
      let isAuthenticated = await auth0AuthProvider.isAuthenticated();
      if (isAuthenticated) {
        let redirectTo =
          new URLSearchParams(window.location.search).get("redirectTo") || "/";
        return redirect(redirectTo);
      }
      return redirect("/");
    },
    Component: () => null,
  },
  {
    path: "/logout",
    async action() {
      // We sign out in a "resource route" that we can hit from a fetcher.Form
      await auth0AuthProvider.signout(window.location.origin + "/login");
      return null;
    },
  },
]);

export default function App() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<p>Initial load...</p>}
    ></RouterProvider>
  );
}

function Layout() {
  return (
    <>
      <Navigate to="/energy" replace />
      <CssVarsProvider>
        <CssBaseline />
        <Box
          sx={{
            minHeight: "100dvh", // TODO: Old browser compatibility
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Box
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              flexGrow: 1,
              // height: `calc(100vh - 77px - 73px)`,
            }}
          >
            <NavBar></NavBar>
            <Box
              sx={{
                paddingTop: "73px", // Nav bar
                px: 2,
                width: "100%",
                paddingBottom: "79px", // Tab bar 77 + 2
                overflowY: "auto",
              }}
            >
              <Outlet />
            </Box>
            <BottomTabBar />
          </Box>
        </Box>
      </CssVarsProvider>
    </>
  );
}

async function protectedLoader({ request }: LoaderFunctionArgs) {
  let isAuthenticated = await auth0AuthProvider.isAuthenticated();
  if (!isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

// // mmm, Profile page already achieves this
// function AuthStatus() {
//   let { user } = useRouteLoaderData("root") as { user: string | null };
//   let fetcher = useFetcher();

//   if (!user) {
//     return <p>You are not logged in.</p>;
//   }

//   let isLoggingOut = fetcher.formData != null;

//   return (
//     <div>
//       <p>Welcome {user}!</p>
//       <fetcher.Form method="post" action="/logout">
//         <Button type="submit" disabled={isLoggingOut}>
//           {isLoggingOut ? "Signing out..." : "Sign out"}
//         </Button>
//       </fetcher.Form>
//     </div>
//   );
// }
