import { User, createAuth0Client } from "@auth0/auth0-spa-js";

type SignInType = "redirect" | "popup";

interface AuthProvider {
  isAuthenticated(): Promise<boolean>;
  user(): Promise<User | null>;
  signin(type: SignInType, redirectTo: string): Promise<void>;
  handleSigninRedirect(): Promise<void>;
  signout(redirectTo?: string): Promise<void>;
  getTokenSilently(): Promise<string>;
}

// Get from an endpoint?
const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

let auth0ClientPromise: ReturnType<typeof createAuth0Client>;

function getClient() {
  if (!auth0ClientPromise) {
    auth0ClientPromise = createAuth0Client({
      clientId: REACT_APP_AUTH0_CLIENT_ID!,
      domain: REACT_APP_AUTH0_DOMAIN!,
      authorizationParams: {
        audience: REACT_APP_AUTH0_AUDIENCE,
      },
    });
  }
  return auth0ClientPromise;
}

export const auth0AuthProvider: AuthProvider = {
  async isAuthenticated() {
    let client = await getClient();
    return client.isAuthenticated();
  },
  async user() {
    let client = await getClient();
    let user = await client.getUser();
    return user || null;
  },
  async signin(type: SignInType, redirectTo: string) {
    let client = await getClient();
    if (type === "redirect") {
      client.loginWithRedirect({
        authorizationParams: {
          audience: REACT_APP_AUTH0_AUDIENCE,
          redirect_uri:
            window.location.origin +
            "/login-result?" +
            new URLSearchParams([["redirectTo", redirectTo]]).toString(),
        },
      });
    } else {
      await client.loginWithPopup();
    }
  },
  async handleSigninRedirect() {
    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      let client = await getClient();
      await client.handleRedirectCallback();
    }
  },
  async signout(redirectTo?: string) {
    let client = await getClient();
    await client.logout({
      openUrl(url) {
        window.location.replace(redirectTo ?? "/");
      },
    });
  },
  async getTokenSilently() {
    const client = await getClient();
    const token = await client.getTokenSilently();
    return token;
  },
};
