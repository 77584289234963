import { auth0AuthProvider } from "./auth";

interface ApiResponse<T> {
  data: T;
}

async function fetchData<T>(
  url: string,
  options: RequestInit = {}
): Promise<T> {
  const accessToken = await auth0AuthProvider.getTokenSilently();
  // console.log(">>> fetch accessToken", accessToken);
  try {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    const { REACT_APP_API_SERVER_URL } = process.env;
    const response = await fetch(`${REACT_APP_API_SERVER_URL}${url}`, options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data: ApiResponse<T> = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data: " + (error as Error).message);
  }
}

export { fetchData };
