import React from "react";
import {
  Form,
  LoaderFunctionArgs,
  redirect,
  useLocation,
} from "react-router-dom";
import { auth0AuthProvider } from "../utils/auth";
import { Box, Button, Link, Stack, Typography } from "@mui/joy";
import { Link as LinkRR } from "react-router-dom";

function Login() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  return (
    <Box
      sx={{
        height: "100dvh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          padding: "16px",
          maxWidth: "380px",
          width: "100%",
          maxHeight: "50%",
        }}
      >
        <div style={{ flexGrow: 1 }}></div>
        <Typography level="h3" sx={{ mb: 6 }}>
          Welcome to EAS
        </Typography>
        <Form method="post" replace>
          <input type="hidden" name="redirectTo" value={from} />
          <Stack spacing={3}>
            {/* <Button type="submit" name="type" value="popup">
              Login with Popup
            </Button> */}
            <Button
              size="lg"
              type="submit"
              name="type"
              value="redirect"
              sx={{ minWidth: "140px", width: "100%", marginY: "32px" }}
            >
              Sign In
            </Button>
            <Typography
              endDecorator={
                <Link
                  component="button"
                  type="submit"
                  name="type"
                  value="redirect"
                >
                  Sign Up
                </Link>
              }
              fontSize="sm"
              sx={{ alignSelf: "center" }}
            >
              Don&apos;t have an account?
            </Typography>
          </Stack>
        </Form>
        <div style={{ flexGrow: 2 }}></div>
        {from && from !== "/" && (
          <Typography level="body-xs">
            <p>You must log in to view the page {from}</p>
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default Login;

export async function loginAction({ request }: LoaderFunctionArgs) {
  let formData = await request.formData();
  let redirectTo = (formData.get("redirectTo") as string | null) || "/";
  let type = formData.get("type") as string | null;
  await auth0AuthProvider.signin(
    type === "redirect" ? "redirect" : "popup",
    redirectTo
  );
  if (type === "popup") {
    return redirect(redirectTo);
  }
  return null;
}

export async function loginLoader() {
  let isAuthenticated = await auth0AuthProvider.isAuthenticated();
  if (isAuthenticated) {
    return redirect("/energy");
  }
  return null;
}
