import React from "react";
import { Activity } from "../../models/Activity";
import {
  Link,
  useLoaderData,
  useNavigate,
  defer,
  Await,
} from "react-router-dom";
import { fetchData } from "../../utils/apiService";
import Button from "@mui/joy/Button";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  ListSubheader,
  Sheet,
  Typography,
} from "@mui/joy";
import { KeyboardArrowRight } from "@mui/icons-material";
import { makeImpactText } from "../../utils/impactText";
import { Dictionary, groupBy } from "lodash";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import ActivityListItemDecorator from "./ActivityListItemDecorator";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export async function loader(from?: string, to?: string) {
  const apiEndpoint = "/v1/activity";

  const now = dayjs();
  const thirtyDaysAgo = now.subtract(30, "days").startOf("day");
  const endOfToday = now.endOf("day");
  const queryParams = {
    from: from ?? thirtyDaysAgo.toISOString(),
    to: to ?? endOfToday.toISOString(),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const url = `${apiEndpoint}?${queryString}`;

  const activitiesPromise = fetchData<Activity[]>(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((activities) => {
    const groups = groupBy(activities, (activity) => {
      return dayjs(activity.dateCreated).startOf("day").format();
    });
    return groups;
  });
  return defer({ activities: activitiesPromise });
}

function ActivityList(): JSX.Element {
  // TODO: Find out how best to strongly type loaders
  const { activities } = useLoaderData() as {
    activities: Promise<Dictionary<Activity[]>>;
  };

  const navigate = useNavigate();

  const showActivity = (id: string) => {
    navigate(`/activity/${id}`);
  };

  const dateName = (date: string): string => {
    const d = dayjs(date);
    if (d.isToday()) return "Today";
    if (d.isYesterday()) return "Yesterday";
    return d.format("DD-MM-YYYY");
  };

  return (
    <Box sx={{ maxWidth: "800px", mx: "auto", my: 2 }}>
      <Typography level="h3" component="h1">
        Your activities
      </Typography>
      <React.Suspense fallback={<p>Loading activities...</p>}>
        <Await
          resolve={activities}
          errorElement={<p>Error loading activities!</p>}
        >
          {(activities: Dictionary<Activity[]>) => (
            <Sheet
              sx={{
                width: "100%",
                mx: "auto",
                my: 4,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
              }}
            >
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Link to={"/activity/create"}>
                  <Button variant="solid">Add</Button>
                </Link>
              </div>
              <List>
                {Object.keys(activities).length > 0 &&
                  Object.keys(activities)
                    .sort((a, b) => (dayjs(b).isBefore(dayjs(a)) ? -1 : 0))
                    .map((date) => (
                      <ListItem nested key={date}>
                        <ListSubheader>{dateName(date)}</ListSubheader>
                        <List>
                          {Object.values(activities[date])
                            .sort((a, b) =>
                              dayjs(b.dateCreated).isBefore(
                                dayjs(a.dateCreated)
                              )
                                ? -1
                                : 0
                            )
                            .map((activity) => (
                              <ListItem key={activity.id}>
                                <ListItemButton
                                  onClick={() => showActivity(activity.id)}
                                >
                                  <ListItemDecorator>
                                    {ActivityListItemDecorator(activity)}
                                  </ListItemDecorator>
                                  <ListItemContent>
                                    <Typography level="title-sm">
                                      {activity.description}
                                    </Typography>
                                    <Typography level="body-xs" my={0.6}>
                                      {`${makeImpactText(
                                        activity.score.value
                                      )}`}
                                    </Typography>
                                    {activity.notes &&
                                      activity.notes.length > 0 && (
                                        <Typography level="body-sm" my={0.8}>
                                          {activity.notes[0].description}
                                        </Typography>
                                      )}
                                  </ListItemContent>
                                  <KeyboardArrowRight />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </ListItem>
                    ))}
              </List>
            </Sheet>
          )}
        </Await>
      </React.Suspense>
    </Box>
  );
}

export default ActivityList;
