import React, { useState } from "react";
import { fetchData } from "../../utils/apiService";
import { Activity, ActivityParams, Note } from "../../models/Activity";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CardContent,
  FormControl,
  Input,
  Card,
  CardActions,
  Stack,
  Typography,
  IconButton,
  Textarea,
  FormLabel,
} from "@mui/joy";
import { ArrowBack, DeleteForever } from "@mui/icons-material";
import { makeImpactText } from "../../utils/impactText";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ActivityTypeSizeDot } from "./ActivityListItemDecorator";

dayjs.extend(relativeTime);

export async function loader(activityId: string) {
  const apiEndpoint = `/v1/activity/${activityId}`;

  const url = `${apiEndpoint}`;
  return await fetchData<Activity>(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

function ActivityItem() {
  const activity = useLoaderData() as Activity;
  const [editedActivity, setEditedActivity] = useState(activity);
  const [loading, setLoading] = useState<null | "save" | "delete">(null);

  const navigate = useNavigate();

  const canSave = () => {
    const canSaveActivity =
      activity.description !== editedActivity.description &&
      editedActivity.description.length > 0;
    const canSaveNotes = activity.notes !== editedActivity.notes;
    return canSaveActivity || canSaveNotes;
  };

  const onSave = async () => {
    setLoading("save");
    const apiEndpoint = `/v1/activity/${activity.id}`;

    const activityParams: ActivityParams = {
      description: editedActivity.description,
      score: editedActivity.score,
      activityType: editedActivity.activityType,
      notes: editedActivity.notes ?? [],
    };

    try {
      await fetchData(apiEndpoint, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(activityParams),
      });
    } catch (error) {
      // TODO: Handle error
      console.error(error);
    }

    navigate(-1);
    setLoading(null);
  };

  const onDelete = async () => {
    setLoading("delete");

    const apiEndpoint = `/v1/activity/${activity.id}`;

    try {
      await fetchData(apiEndpoint, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      // TODO: Handle error
      console.error(error);
    }

    navigate(-1);
    setLoading(null);
  };

  const onDescriptionChange = (text: string) => {
    setEditedActivity({ ...editedActivity, description: text });
  };

  const onNoteChange = (text: string) => {
    setEditedActivity({ ...editedActivity, notes: [{ description: text }] });
  };

  const impactText = makeImpactText(editedActivity.score.value);

  let firstNoteDescription: string | null = null;
  if (editedActivity.notes && editedActivity.notes?.length > 0) {
    firstNoteDescription = editedActivity.notes[0].description;
  }

  return (
    <Box component="main" sx={{ maxWidth: "800px", mx: "auto", my: 2 }}>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography level="h4" component="h1">
          Activities
        </Typography>
      </Stack>
      <Card
        sx={{
          width: "100%",
          mx: "auto", // margin left & right
          my: 4, // margin top & bottom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Stack direction="row" spacing={1.6} alignItems="center">
                <ActivityTypeSizeDot {...activity}></ActivityTypeSizeDot>
                <Input
                  value={editedActivity.description}
                  onChange={(event) => onDescriptionChange(event.target.value)}
                ></Input>
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                minRows={1}
                value={firstNoteDescription ?? undefined}
                onChange={(event) => onNoteChange(event.target.value)}
              ></Textarea>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ paddingY: "12px", paddingLeft: "6px" }}
          >
            <Typography level="body-sm">
              {`${impactText} ${editedActivity.activityType}`}{" "}
            </Typography>
            <Typography level="body-sm" textColor="text.secondary">
              {dayjs(activity.dateCreated).from(dayjs())}
            </Typography>
          </Stack>

          <CardActions>
            <Button
              fullWidth
              variant="soft"
              color="danger"
              endDecorator={<DeleteForever />}
              disabled={loading === "save"}
              onClick={onDelete}
            >
              Delete
            </Button>
            <Button
              fullWidth
              loading={loading === "delete"}
              disabled={!canSave()}
              onClick={onSave}
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ActivityItem;
