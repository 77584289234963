import { Typography } from "@mui/joy";
import React from "react";

function Insight() {
  return (
    <Typography level="h3" component="h1">
      Insights
    </Typography>
  );
}

export default Insight;
